<template>
  <div class="setBox">
    <div class="boxTop">
      <div class="topLeft" @click="goBack()"></div>
      <div class="topCon">账号管理</div>
    </div>
    <div class="formBox">
      <van-form>
        <van-cell
          title="更换手机号"
          is-link
          v-model="mobile"
          @click="goEditMobile()"
        />
        <van-cell title="修改密码" is-link @click="goEditPassword()" />
        <div class="back"></div>
        <div class="binding">第三方账号绑定</div>
        <van-cell title="绑定微信" is-link />
      </van-form>
    </div>
    <div class="footer" @click="goLogOffPage()">注销账号</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      mobile: '',
      mobile1: "",
    };
  },
  mounted() {
    this.mobile = this.$store.state.user.mobile.toString()
    this.mobile = this.mobile.substr(0, 3) + "****" + this.mobile.substr(7);
  },
  methods: {
    goBack() {
      this.$router.push({ path: "/setIndex" });
    },
    // 跳转偏好管理页面
    goManagePage() {
      this.$router.push({ path: "/managePage" });
    },
    // 跳转更改手机号
    goEditMobile() {
      this.$router.push({ path: "editMobile" });
    },
    // 修改密码
    goEditPassword() {
      this.$router.push({ path: "editPassword" });
    },
    goLogOffPage() {
      this.$router.push({
        path: "logOffPage",
        query: { mobile: this.mobile, mobile1: this.mobile1 },
      });
    },
  },
};
</script>

<style scoped>
.boxTop {
  display: flex;
  align-items: center;
  margin-top: 18px;
}
.topLeft {
  width: 48px;
  height: 48px;
  background-image: url("../../../image/back.png");
  background-size: 100% 100%;
  margin-left: 32px;
}
.topCon {
  font-size: 32px;
  font-weight: 600;
  color: #0b1526;
  margin-left: 240px;
}
.formBox {
  margin-top: 32px;
}
.van-cell {
  /* line-height: 60px; */
  font-size: 28px;
  font-weight: 400;
  color: #455669;
}
.back {
  width: 750px;
  height: 20px;
  background: #f6f8fa;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
}
.binding {
  font-size: 28px;
  font-weight: 400;
  color: #97a4b4;
  margin-left: 32px;
  margin-top: 32px;
  margin-bottom: 32px;
}
.footer {
  border-bottom: 1px solid #97a4b4;
  font-size: 28px;
  font-weight: 400;
  color: #97a4b4;
  position: fixed;
  bottom: 54px;
  left: 319px;
}
</style>
